import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Role } from '@etrucking/iam/core';

export interface RolesState
  extends EntityState<Role, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'iam.roles' })
export class RolesStore extends EntityStore<RolesState> {
  constructor() {
    super();
  }
}
