<div class="page">
  <!-- sidebar-->
  <div class="sidebar sidebar_info">
    <div class="sidebar__nav">
      <!-- nav-->
      <nav
        class="nav js-nav"
        [ngClass]="{ extended: (sidenavExtended$ | async) === false }"
      >
        <a class="nav__link nav__link_head" [routerLink]="['/']">
          <div class="nav__preview">
            <img class="nav__pic" src="/assets/img/logo.svg" alt="Logo" />
          </div>
          <div class="nav__title">
            <img class="nav__pic" src="/assets/img/brand.svg" alt="betaCRM" />
          </div>
        </a>
        <div class="nav__list">
          <!-- mobile primary menu-->
          <div class="nav__primary js-nav-primary">
            <div class="nav__group">
              <a
                class="nav__link"
                *ngFor="let link of primaryNavLinks$ | async"
                [routerLink]="link.segments"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{ exact: false }"
                [attr.title]="link.label"
              >
                <div class="nav__preview">
                  <etrk-icon [name]="link.icon"></etrk-icon>
                </div>
                <div class="nav__title">{{ link.label }}</div>
              </a>

              <a
                class="nav__link nav__link_menu js-nav-link-menu"
                (click)="uiCommand.toggleNavModile()"
              >
                <div class="nav__preview">
                  <i class="la la-bars"></i>
                </div>
              </a>
            </div>
          </div>
          <!-- mobile secondary menu-->
          <div
            class="nav__secondary js-nav-secondary"
            [ngClass]="{ visible: showExtendedNavMobile$ | async }"
          >
            <div class="nav__group">
              <a
                class="nav__link"
                *ngFor="let link of secondaryNavLinks$ | async"
                [routerLink]="['/', link.name]"
                [routerLinkActive]="'active'"
                [attr.title]="link.label"
              >
                <div class="nav__preview">
                  <etrk-icon [name]="link.icon"></etrk-icon>
                </div>
                <div class="nav__title">{{ link.label }}</div>
              </a>

              <span style="flex-grow: 1"></span>
              <a
                class="nav__link nav__link_signout"
                style="cursor: pointer"
                (click)="signOut()"
                title="Déconnexion"
              >
                <div class="nav__preview">
                  <i class="la la-sign-out-alt"></i>
                </div>
                <div class="nav__title"></div>
              </a>
              <a
                class="nav__link nav__link_user nav__link_signout"
                [routerLink]="['/', 'iam', 'profile']"
                title="{{ userDisplayName$ | async }}"
              >
                <div class="nav__preview">
                  <!-- <img
                  class="nav__pic"
                  src="/assets/img/user-1.jpg"
                  alt="User"
                /> -->
                  <span class="nav__avatar">{{ userAvatar$ | async }}</span>
                  <div class="nav__online online"></div>
                </div>
                <div class="nav__title">{{ userDisplayName$ | async }}</div>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <!-- <div
      class="sidebar__container"
      *ngIf="(sidePanelEnabled$ | async)!"
      [ngClass]="{ toggled: (sidenavExtended$ | async) === false }"
    >
      <div class="sidebar__head">
        <div class="sidebar__preview">
          <i
            *ngIf="sidebarShowIcon$ | async; else pic"
            class="la la-{{ sidebarIcon$ | async }}"
          ></i>
          <ng-template #pic>
            <img
              class="sidebar__pic"
              src="/assets/{{ sidebarPictureUrl$ | async }}"
              alt="{{ sidebarTitle$ | async }}"
            />
          </ng-template>
        </div>
        <div class="sidebar__wrap">
          <div class="sidebar__title">{{ sidebarTitle$ | async }}</div>
          <div class="sidebar__text">{{ sidebarText$ | async }}</div>
        </div>
        <button
          class="sidebar__action sidebar__action_toggle action"
          (click)="uiCommand.toggleSidenavExtended()"
        >
          <i class="la la-angle-left"></i>
        </button>

        <button
          *ngIf="sidebarShowBackControl$ | async"
          class="sidebar__action action"
          (click)="back()"
        >
          <i class="la la-times-circle"></i>
        </button>
      </div>
      <div class="sidebar__inner" id="sidepanel-container">
        
      </div>
    </div> -->
  </div>
  <!-- container-->
  <div
    class="container"
    [ngClass]="{ extended: (sidenavExtended$ | async) === false }"
  >
    <div class="container__head">
      <button
        type="menu"
        class="
          container__action container__action_menu
          action
          js-container-toggle
          nav-extender
        "
        (click)="uiCommand.toggleSidenavExtended()"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 12">
          <defs />
          <g fill="none" fill-rule="evenodd">
            <path d="M-5-6h24v24H-5z" opacity=".87" />
            <path
              fill="currentColor"
              d="M.59 1.41L5.18 6 .59 10.59 2 12l6-6-6-6L.59 1.41zM11 0h2v12h-2V0z"
            />
          </g>
        </svg>
      </button>
      <div class="container__title title title_md">
        <span>{{ title$ | async }} </span>
        <div id="title-info__container">
          <!-- View Details Injected Here -->
        </div>
      </div>
      <!-- search-->
      <div
        class="container__search search js-search"
        [ngClass]="{ open: backdropSearchVisible$ | async }"
      >
        <!-- <button class="settings__btn btn btn_light mobile-hide">Annuler</button>
      <button class="settings__btn btn">Créer le transport</button> -->
      </div>
      <!-- new-->
      <div
        class="container__new new js-new"
        [ngClass]="{ open: backdropNewVisible$ | async }"
      >
        <button
          class="new__action action js-new-open"
          (click)="uiCommand.toggleNewMenu()"
        >
          <i class="la la-plus-circle"></i>
        </button>
        <div class="new__dropdown js-new-dropdown">
          <a class="new__item" href="#">
            <div class="new__icon">
              <i class="la la-shipping-fast"></i>
            </div>
            <div class="new__title">Nouveau transport</div>
          </a>
          <a class="new__item" href="#">
            <div class="new__icon">
              <i class="la la-clipboard-check"></i>
            </div>
            <div class="new__title">Nouvelle assignation</div>
          </a>
          <a class="new__item" href="#">
            <div class="new__icon">
              <i class="la la-toolbox"></i>
            </div>
            <div class="new__title">Nouveau client</div>
          </a>
          <a class="new__item" href="#">
            <div class="new__icon">
              <i class="la la-toolbox"></i>
            </div>
            <div class="new__title">Nouveau courtier</div>
          </a>
        </div>
        <div
          class="new__backdrop backdrop js-new-backdrop"
          (click)="uiCommand.toggleNewMenu()"
        ></div>
      </div>
      <div class="members__item members__item-avatar">
        {{ userAvatar$ | async }}
      </div>
    </div>
    <div class="container__body">
      <!-- router slot-->
      <div [@routeAnimations]="prepareRoute(outlet)">
        <router-outlet #outlet="outlet"></router-outlet>
        <router-outlet name="modal"></router-outlet>
      </div>
    </div>
  </div>
</div>

<ng-template #swUpdateTpl let-toastRef>
  <div style="display: flex">
    <span style="padding-right: 12px">Une nouvelle version est disponible</span>
    <button class="action action_title active" (click)="doAppUpdate()">
      Installer
    </button>
  </div>
</ng-template>
