import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthQuery } from '@etrucking/iam/state';
import { Logger } from '@etrucking/shared/utils/logger';

const log = new Logger('AuthorizedGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthorizedGuard implements CanActivate {
  constructor(private authQuery: AuthQuery, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    const permissions = next.data?.permissions;
    return (
      !permissions ||
      !permissions.length ||
      permissions.includes('*') ||
      this.hasPermissions(permissions)
    );
  }

  hasPermissions(permissions: string[]): any {
    return this.authQuery.role$.pipe(
      map((role) =>
        permissions.some((permission) => role?.permissions[permission] === true)
      ),
      tap((hasPermission) => log.debug('hasPermissions()', hasPermission)),
      tap((hasPermission) => {
        if (hasPermission) return;
        this.router.navigate(['/']);
        log.debug('hasPermissions() > redirect to home');
      })
    );
  }
}
