import { CollectionConfig, CollectionService } from 'akita-ng-fire';

import { Injectable } from '@angular/core';

import { RolesState, RolesStore } from './roles.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'roles', idKey: 'code' })
export class RolesService extends CollectionService<RolesState> {
  constructor(store: RolesStore) {
    super(store);
  }
}
