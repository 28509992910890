import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { NavLink } from '../model';

export interface UiState {
  title: string;
  searchTerm: string;
  sidenavExtended: boolean;
  showExtendedNavMobile: boolean;
  backdropNewVisible: boolean;
  backdropSearchVisible: boolean;
  searchResultsVisible: boolean;
  sidePanelEnabled: boolean;
  sidebarHeader: {
    showIcon: boolean;
    showBackControl: boolean;
    icon: string;
    imageUrl: string;
    title: string;
    description: string;
  };
  navLinks: NavLink[];
}

localStorage.setItem(
  'etrk-ui-sidebarAvatar',
  JSON.stringify({
    icon: '',
    showIcon: false,
    showBackControl: false,
    imageUrl: 'img/white-ui-store.png',
    title: 'TRANSPORT AMF',
    description: 'Transporteur',
  })
);

export function createInitialState(): UiState {
  return {
    title: '',
    searchTerm: '',
    sidenavExtended: false,
    showExtendedNavMobile: false,
    backdropNewVisible: false,
    backdropSearchVisible: false,
    searchResultsVisible: false,
    sidePanelEnabled: true,
    sidebarHeader: {
      icon: '',
      showBackControl: false,
      showIcon: false,
      imageUrl: 'img/white-ui-store.png',
      title: 'TRANSPORT AMF',
      description: 'Transporteur',
    },
    navLinks: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ui' })
export class UiStore extends Store<UiState> {
  constructor() {
    super(createInitialState());
  }
}
