import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { InvitesStore, InvitesState } from './invites.store';

@Injectable({ providedIn: 'root' })
export class InvitesQuery extends QueryEntity<InvitesState> {
  constructor(protected store: InvitesStore) {
    super(store);
  }
}
