import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AnonymousGuard } from '@etrucking/iam/uikit';
import { AppRoute, NavLink } from '@etrucking/shared/uikit/core';

import { HomeComponent } from './home';

// TODO: remove everything related to this route
export const routes: AppRoute[] = [
  { path: '', redirectTo: '/assistant', pathMatch: 'full' },
  {
    path: 'assistant',
    component: HomeComponent,
    data: { title: 'Dashboard' },
    canActivate: [AnonymousGuard],
  },
  {
    path: 'invoices',
    loadChildren: () =>
      import('@etrucking/billing/shell').then((m) => m.ShellModule),
    data: { title: 'Facturation' },
    canActivate: [AnonymousGuard],
  },
  {
    path: 'booking',
    loadChildren: () =>
      import('@etrucking/booking/shell').then((m) => m.BookingShellModule),
    canActivate: [AnonymousGuard],
  },
  {
    path: 'dispatches',
    loadChildren: () =>
      import('@etrucking/dispatches/shell-admin').then(
        (m) => m.AdminDispatchesShellModule
      ),
    canActivate: [AnonymousGuard],
    data: { title: 'Tableau de répartition' },
  },
  {
    path: 'iam',
    loadChildren: () =>
      import('@etrucking/iam/shell-admin').then((m) => m.AccountShellModule),
    canActivate: [AnonymousGuard],
  },
  {
    path: 'crm',
    loadChildren: () =>
      import('@etrucking/crm/shell').then((m) => m.CrmShellModule),
  },
  {
    path: 'fleet',
    loadChildren: () =>
      import('@etrucking/fleet/shell').then((m) => m.FleetShellModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const links: NavLink[] = [
  {
    name: 'assistant',
    label: 'Assistant',
    icon: 'home',
    isPrimary: true,
    segments: ['/', 'assistant'],
  },
  {
    name: 'booking',
    label: 'Transports',
    icon: 'box', // <i class="las la-box"></i>
    isPrimary: true,
    segments: ['/', 'booking'],
  },
  {
    name: 'crm',
    label: 'Contacts',
    icon: 'briefcase',
    isPrimary: true,
    segments: ['/', 'crm'],
  },
  {
    name: 'dispatch',
    label: 'Répartiteur',
    icon: 'clipboard-check',
    isPrimary: true,
    segments: ['/', 'dispatches'],
  },
  {
    name: 'invoices',
    label: 'Comptabilité',
    icon: 'landmark', //book
    isPrimary: false,
    segments: ['/', 'dispatch'],
  },
  {
    name: 'fleet',
    label: 'Flotte',
    icon: 'truck',
    isPrimary: true,
    segments: ['/', 'fleet'],
  },
  {
    name: 'settings',
    label: 'Paramètres',
    icon: 'cog',
    isPrimary: false,
    segments: ['/', 'settings'],
  },
];
