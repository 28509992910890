import { filter, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { UiState, UiStore } from './ui.store';

@Injectable({ providedIn: 'root' })
export class UiQuery extends Query<UiState> {
  readonly title$ = this.select((state) => state.title);
  readonly searchTerm$ = this.select((state) => state.searchTerm);
  readonly sidenavExtended$ = this.select((state) => state.sidenavExtended);
  readonly showExtendedNavMobile$ = this.select(
    (state) => state.showExtendedNavMobile
  );
  readonly backdropNewVisible$ = this.select(
    (state) => state.backdropNewVisible
  );
  readonly backdropSearchVisible$ = this.select(
    (state) => state.backdropSearchVisible
  );
  readonly searchResultsVisible$ = this.select(
    (state) => state.searchResultsVisible
  );

  readonly sidePanelEnabled$ = this.select((state) => state.sidePanelEnabled);

  readonly sidebarHeader$ = this.select((state) => state.sidebarHeader);
  readonly sidebarHeaderDescription$ = this.select(
    (state) => state.sidebarHeader.description
  );
  readonly sidebarHeaderIcon$ = this.select(
    (state) => state.sidebarHeader.icon
  );
  readonly sidebarHeaderImageUrl$ = this.select(
    (state) => state.sidebarHeader.imageUrl
  );
  readonly sidebarHeaderShowBackControl$ = this.select(
    (state) => state.sidebarHeader.showBackControl
  );
  readonly sidebarHeaderShowIcon$ = this.select(
    (state) => state.sidebarHeader.showIcon
  );
  readonly sidebarHeaderTitle$ = this.select(
    (state) => state.sidebarHeader.title
  );

  readonly navLinks$ = this.select((state) => state.navLinks);
  readonly primaryNavLinks$ = this.navLinks$.pipe(
    map((links) => links.filter((link) => link.isPrimary))
  );
  readonly secondaryNavLinks$ = this.navLinks$.pipe(
    map((links) => links.filter((link) => !link.isPrimary))
  );

  constructor(protected store: UiStore) {
    super(store);
  }
}
