import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  BrokersQuery,
  BrokersService,
  CustomersQuery,
  CustomersService,
  DriversQuery,
  DriversService,
  LocationsQuery,
  LocationsService,
} from '@etrucking/crm/state';

import { MessagingService } from './messaging.service';

@Component({
  selector: 'etrucking-admin-root',
  template: ` <router-outlet></router-outlet> `,
  styles: [``],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = 'etrucking-admin';
  message!: any;

  constructor(private messagingService: MessagingService) {}

  ngOnInit() {}
}
