import { switchAll, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { OrganizationsQuery } from '../organizations';
import { ProfilesQuery } from '../profiles';
import { RolesQuery } from '../roles';
import { AuthState, AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  readonly session$ = this.select((state) => state.session);
  readonly profile$ = this.select((state) => state.profile);
  readonly loading$ = this.select((state) => state.loading);
  readonly emailVerified$ = this.select((state) => state.loading);
  readonly role$ = this.select((state) => state.roles);

  readonly userOrganization$ = this.select(
    (state) => state.organizationId
  ).pipe(
    switchMap((organizationId: string) =>
      this.orgsQuery.selectAll({
        asObject: true,
        filterBy: (org) => org.id === organizationId,
      })
    )
  );

  readonly userRole$ = this.select((state) => state.roles);

  constructor(
    protected store: AuthStore,
    private orgsQuery: OrganizationsQuery,
    private rolesQuery: RolesQuery,
    private profilesQuery: ProfilesQuery
  ) {
    super(store);
  }
}
