import { CollectionConfig, CollectionService } from 'akita-ng-fire';

import { Injectable } from '@angular/core';
import { Invite, InviteRegistration } from '@etrucking/iam/core';

import { AuthQuery } from '../auth/auth.query';
import { createInvite } from './invite.model';
import { InvitesState, InvitesStore } from './invites.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'invites' })
export class InvitesService extends CollectionService<InvitesState> {
  constructor(store: InvitesStore, private authQuery: AuthQuery) {
    super(store);
  }

  inviteDriver(invite: Partial<Invite>) {
    const { uid, organizationId } = this.authQuery.getValue();
    return this.add(
      createInvite({
        invitedBy: uid,
        organizationId,
        contactInfo: invite.contactInfo,
        contactMethod: invite.contactMethod as any,
        registration: InviteRegistration.Driver,
      })
    );
  }
}
