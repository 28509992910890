import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Account } from '@etrucking/iam/core';

export interface AccountsState
  extends EntityState<Account, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'iam.accounts', idKey: 'userId' })
export class AccountsStore extends EntityStore<AccountsState> {
  constructor() {
    super();
  }
}
