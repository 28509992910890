import { FireAuthState, RoleState } from 'akita-ng-fire';

import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Organization, Role, Session, User } from '@etrucking/iam/core';

export interface Profile {
  userId: string;
  firstName: string;
  lastName: string;
  isOnline: string;
  email: string;
  phoneNumber: string;
  avatarUrl?: string;
}

export interface AuthState
  extends FireAuthState<Profile | undefined>,
    RoleState<Role | undefined> {
  session: Session;
  redirectUrl: string;
  organizationId: string;
  role: string;
}

export function createInitialState(): AuthState {
  return {
    session: Session.Anonymous,
    redirectUrl: '',
    uid: '',
    role: '',
    organizationId: '',
    emailVerified: false,
    loading: true,
    profile: undefined,
    roles: undefined,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth', resettable: true })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }
}
