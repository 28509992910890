import { CollectionConfig, CollectionService } from 'akita-ng-fire';
import { firestore } from 'firebase';

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { setEditTimestamp, setFullTimestamp } from '@etrucking/shared/state';
import { Logger } from '@etrucking/shared/utils/logger';

import { OrganizationsMapper } from './organizations.mapper';
import { OrganizationsState, OrganizationsStore } from './organizations.store';
import { Organization } from '@etrucking/iam/core';

const logger = new Logger('OrganizationsService');

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'organizations', idKey: 'id', resetOnUpdate: true })
export class OrganizationsService extends CollectionService<OrganizationsState> {
  mapper = new OrganizationsMapper();

  constructor(protected store: OrganizationsStore) {
    super(store);
  }

  setActive(id: string): void {
    this.store.setActive(id);
  }

  addOrganization(params: any, write?: firestore.WriteBatch) {
    const organization = this.mapper.toDomain(params);

    return this.add(organization, { write });
  }

  editOrganization(params: any, write?: firestore.WriteBatch) {
    const organization = this.mapper.toDomain({
      ...params,
      ...setEditTimestamp(),
    });

    logger.info('editOrganization() => params', organization);

    return this.update(organization.id as any, organization, { write });
  }
}
