import { BehaviorSubject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { UiService } from '../state';
import { APP_NAME } from '../tokens';

@Injectable({ providedIn: 'root' })
export class TitleService {
  private _title$ = new BehaviorSubject<string>(this.appName);
  readonly title$ = this._title$.asObservable();

  constructor(
    private title: Title,
    private uiController: UiService,
    @Inject(APP_NAME) private appName: string
  ) {}

  setTitle(title: string) {
    this.title.setTitle(`${title} | ${this.appName}`);
    this._title$.next(title);
    this.uiController.setTitle(title);
  }
}
