import { Directive, EventEmitter, OnDestroy, Output } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Logger } from '@etrucking/shared/utils/logger';
import { HotToastRef } from '@ngneat/hot-toast';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

/**
 *
 *
 * @export
 * @abstract
 * @class BaseView
 * @implements {OnDestroy}
 */
@Directive()
export abstract class BaseView implements OnDestroy {
  /**
   *
   *
   * @type {Subject<void>}
   * @memberof BaseView
   */
  #destroy$!: Subject<void>;
  /**
   *
   *
   * @type {EventEmitter<string>}
   * @memberof BaseView
   */
  #pageRedirect$!: EventEmitter<string>;
  /**
   *
   *
   * @type {EventEmitter<void>}
   * @memberof BaseView
   */
  #pageBack$!: EventEmitter<void>;
  /**
   *
   *
   * @type {EventEmitter<string>}
   * @memberof BaseView
   */
  #pageTitleChange$!: EventEmitter<string>;
  /**
   *
   *
   * @type {Logger}
   * @memberof BaseView
   */
  #logger!: Logger;

  #toastRef!: HotToastRef;

  /**
   *
   *
   * @type {BehaviorSubject<boolean>}
   * @memberof BaseView
   */
  #formSubmitted$!: BehaviorSubject<boolean>;

  /**
   *
   *
   * @protected
   * @abstract
   * @type {string}
   * @memberof BaseView
   */
  protected abstract name: string;
  /**
   *
   *
   * @readonly
   * @protected
   * @type {Observable<void>}
   * @memberof BaseView
   */
  protected get destroy$(): Observable<void> {
    if (!this.#destroy$) {
      this.#destroy$ = new Subject();
    }

    return this.#destroy$.asObservable();
  }
  /**
   *
   *
   * @readonly
   * @protected
   * @type {Observable<boolean>}
   * @memberof BaseView
   */
  get formSubmitted$(): Observable<boolean> {
    if (!this.#formSubmitted$) {
      this.#formSubmitted$ = new BehaviorSubject<boolean>(false);
    }

    return this.#formSubmitted$.asObservable();
  }

  /**
   *
   *
   * @readonly
   * @type {EventEmitter<void>}
   * @memberof BaseView
   */
  @Output() get pageBack(): EventEmitter<void> {
    if (!this.#pageBack$) {
      this.#pageBack$ = new EventEmitter();
    }

    return this.#pageBack$;
  }
  /**
   *
   *
   * @readonly
   * @type {EventEmitter<string>}
   * @memberof BaseView
   */
  @Output() get pageRedirect(): EventEmitter<string> {
    if (!this.#pageRedirect$) {
      this.#pageRedirect$ = new EventEmitter();
    }

    return this.#pageRedirect$;
  }
  /**
   *
   *
   * @readonly
   * @protected
   * @type {Logger}
   * @memberof BaseView
   */
  protected get logger(): Logger {
    if (!this.#logger) {
      this.#logger = new Logger(this.name);
    }

    return this.#logger;
  }
  /**
   *
   *
   * @readonly
   * @type {EventEmitter<string>}
   * @memberof BaseView
   */
  @Output() get pageTitleChange(): EventEmitter<string> {
    if (!this.#pageTitleChange$) {
      this.#pageTitleChange$ = new EventEmitter();
    }

    return this.#pageTitleChange$;
  }
  /**
   *
   *
   * @memberof BaseView
   */
  ngOnDestroy(): void {
    if (this.#destroy$) {
      this.#destroy$.next();
      this.#destroy$.complete();
      this.logger.debug(`ngOnDestroy()`);
    }
  }

  protected onFormSubmit(success = true) {
    if (!this.formSubmitted$) {
      throw 'onFormSubmit() called but no subscribers for formSubmitted$ observable';
    }

    this.#formSubmitted$.next(success);
  }
}
