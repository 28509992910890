import {
  animate,
  keyframes,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const listAnimation = trigger('listAnimation', [
  // Transition from any state to any state
  transition('* => *', [
    // Initially the all cards are not visible
    query(':enter', style({ opacity: 0 }), { optional: true }),

    // Each card will appear sequentially with the delay of 300ms
    query(
      ':enter',
      stagger('300ms', [
        animate(
          '.5s cubic-bezier(0.35, 0, 0.25, 1)',
          keyframes([
            style({ opacity: 0, transform: 'translateY(-50%)', offset: 0 }),
            style({
              opacity: 0.5,
              transform: 'translateY(-10px)',
              offset: 0.3,
            }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1 }),
          ])
        ),
      ]),
      { optional: true }
    ),

    // Cards will disappear sequentially with the delay of 300ms
    // query(
    //   ':leave',
    //   stagger('300ms', [
    //     animate(
    //       '500ms ease-out',
    //       keyframes([
    //         style({ opacity: 1, transform: 'scale(1.1)', offset: 0 }),
    //         style({ opacity: 0.5, transform: 'scale(.5)', offset: 0.3 }),
    //         style({ opacity: 0, transform: 'scale(0)', offset: 1 }),
    //       ])
    //     ),
    //   ]),
    //   { optional: true }
    // ),
  ]),
]);
