import { CollectionConfig, CollectionService } from 'akita-ng-fire';

import { Injectable } from '@angular/core';

import { ProfilesState, ProfilesStore } from './profiles.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'profiles', idKey: 'userId', resetOnUpdate: true })
export class ProfilesService extends CollectionService<ProfilesState> {
  constructor(store: ProfilesStore) {
    super(store);
  }
}
