import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AddressPipe, CountryPipe } from './pipes/address.pipe';
import { NoEmptyPipe } from './pipes/replace-empty.pipe';
import { LoadSchedulePipe } from './pipes/load-schedule.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [CountryPipe, AddressPipe, NoEmptyPipe, LoadSchedulePipe],
  exports: [CountryPipe, AddressPipe, NoEmptyPipe, LoadSchedulePipe],
})
export class CoreModule {}
