import * as firebase from 'firebase/app';

import { Timestamp } from '@etrucking/shared/core';

export const setFullTimestamp = () => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  return {
    createdAt: timestamp as Timestamp,
    updatedAt: timestamp as Timestamp,
  };
};

export const setEditTimestamp = () => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  return {
    updatedAt: timestamp as Timestamp,
  };
};

export const getCreateTimestamp = () => {
  const createdAt = new Date();
  return {
    createdAt,
    updatedAt: createdAt,
  };
};

export const getEditTimestamp = () => {
  return {
    updatedAt: new Date(),
  };
};

export const toDate = function (date: Timestamp | null): any {
  return date ? new Date(date.toDate()) : null;
};

export const toTimestamp = function (date: Date | null): any {
  return date ? firebase.firestore.Timestamp.fromDate(new Date(date)) : null;
};

[
  {
    id: 'xLK78Byxprj3BXNo6IXz',
    bookingNumber: 'V170721-00',
    dispatchNumber: '',
    purchaseOrderNumber: '',
    trackingCode: 'AMF-1626528855688',
    type: 0,
    status: 0,
    pickupLocationId: 'Z4xfq3y2GPAOTk9RFAwW',
    pickupSchedule: null,
    pickupDateType: 'DATE',
    pickupDate: '2021-07-18T16:00:00.000Z',
    pickupDateTimeFrom: null,
    pickupDateTimeTo: null,
    pickupContactName: '',
    pickupContactEmail: null,
    pickupContactPhoneNumber: null,
    pickupContactFax: null,
    pickupBOL: '1000P',
    pickupInstructions: '',
    deliveryLocationId: 'IKFftryt8XPKoOCOVPwk',
    deliverySchedule: null,
    deliveryDateType: 'DATE',
    deliveryDate: null,
    deliveryDateTimeFrom: null,
    deliveryDateTimeTo: null,
    deliveryContactName: null,
    deliveryContactEmail: null,
    deliveryContactPhoneNumber: null,
    deliveryContactFax: null,
    deliveryBOL: '1000D',
    deliveryInstructions: '',
    deliveryDealine: null,
    customerId: '04f6lb4UZ9gc1fgMcdAH',
    shipperId: 'pGlzdtRyHlzYWmn6Rmkb',
    consigneeId: 'XRHZRjwnpoAs968gWFJD',
    organizationId: 'pGlzdtRyHlzYWmn6Rmkb',
    freightDescription: 'Billes de bois',
    freightWeight: '7',
    freightWeightUnit: 't',
    freightNotes: null,
    freightEstimatedWeight: null,
    freightActualWeight: null,
    trailerGroup: null,
    isLoadTypeHazMat: false,
    isLoadTypeOversized: false,
    isLoadTypeReefer: false,
    isLoadTypeTanker: false,
    reeferTemp: null,
    reeferTempUnit: null,
    autoMilesCalculation: false,
    totalMiles: null,
    haulingFeeMethod: null,
    haulingRate: null,
    haulingAmount: null,
    fuelSurchageMethod: null,
    fuelSurchageRate: null,
    fuelSurchageAmount: null,
    extraItems: [],
    isArchived: false,
    createdAt: '2021-07-17T13:34:15.688Z',
    updatedAt: '2021-07-17T13:34:15.688Z',
    customer: {
      id: '04f6lb4UZ9gc1fgMcdAH',
      updatedAt: { seconds: 1625886867, nanoseconds: 371544000 },
      createdAt: { seconds: 1625886867, nanoseconds: 371544000 },
      legalName: 'GRAINS ST-LAURENT',
      type: 'CUSTOMER',
      archived: false,
      primaryContact: {
        phoneNumber: '',
        email: '',
        firstName: '',
        lastName: '',
      },
      address: {
        zipOrPostalCode: 'H2V2G3',
        country: '',
        street1: '417 MC GILL STREET SUITE 315',
        stateOrProvince: 'QC',
        street2: '',
        city: 'MONTRÉAL',
      },
      displayName: 'Grains St-Laurent',
    },
    pickupLocationInfo: {
      id: 'Z4xfq3y2GPAOTk9RFAwW',
      name: 'LOC AMF 1',
      organizationId: 'pGlzdtRyHlzYWmn6Rmkb',
      type: null,
      address: {
        street1: '2456 rue Parc',
        city: 'Sherbrooke',
        zipOrPostalCode: ' \tJ1E 3B2',
        country: 'CA',
        stateOrProvince: 'QC',
        street2: null,
      },
      unlocode: null,
      consigneeOrConsignor: {
        firstName: null,
        email: null,
        lastName: null,
        phoneNumber: null,
      },
      driverInstructions: null,
      requiresAppointment: null,
    },
    deliveryLocationInfo: {
      id: 'IKFftryt8XPKoOCOVPwk',
      driverInstructions: null,
      name: 'Port de montréal',
      requiresAppointment: null,
      type: null,
      unlocode: null,
      organizationId: 'XRHZRjwnpoAs968gWFJD',
      address: {
        stateOrProvince: 'QC',
        street1: '3335',
        country: 'CA',
        street2: '',
        city: 'VILLE',
        zipOrPostalCode: 'CODEPOSTAL',
      },
      consigneeOrConsignor: {
        phoneNumber: null,
        email: null,
        lastName: null,
        firstName: null,
      },
    },
  },
  {
    id: 'CpTpRiCly5vcZ3uzgTak',
    bookingNumber: 'D120721-02',
    dispatchNumber: '',
    purchaseOrderNumber: null,
    trackingCode: 'AMF-1626057643898',
    type: 0,
    status: 0,
    pickupLocationId: 'IKFftryt8XPKoOCOVPwk',
    pickupSchedule: null,
    pickupDateType: 'WINDOW',
    pickupDate: '2021-07-12T04:33:00.000Z',
    pickupDateTimeFrom: '2021-07-11T13:13:00.000Z',
    pickupDateTimeTo: '2021-07-11T21:13:00.000Z',
    pickupContactName: null,
    pickupContactEmail: null,
    pickupContactPhoneNumber: null,
    pickupContactFax: null,
    pickupBOL: '1000',
    pickupInstructions: '',
    deliveryLocationId: 'jNBbso0wZaYJS6c3xnzW',
    deliverySchedule: null,
    deliveryDateType: 'DATE',
    deliveryDate: '1970-01-01T00:00:00.000Z',
    deliveryDateTimeFrom: '1970-01-01T00:00:00.000Z',
    deliveryDateTimeTo: '1970-01-01T00:00:00.000Z',
    deliveryContactName: null,
    deliveryContactEmail: null,
    deliveryContactPhoneNumber: null,
    deliveryContactFax: null,
    deliveryBOL: '1001',
    deliveryInstructions: '',
    deliveryDealine: null,
    customerId: '0Fn4bALZw57Js0spXlOq',
    shipperId: 'XRHZRjwnpoAs968gWFJD',
    consigneeId: 'pGlzdtRyHlzYWmn6Rmkb',
    organizationId: 'pGlzdtRyHlzYWmn6Rmkb',
    freightDescription: 'Grain',
    freightWeight: '8',
    freightWeightUnit: 't',
    freightNotes: null,
    freightEstimatedWeight: null,
    freightActualWeight: null,
    trailerGroup: null,
    isLoadTypeHazMat: false,
    isLoadTypeOversized: false,
    isLoadTypeReefer: false,
    isLoadTypeTanker: false,
    reeferTemp: null,
    reeferTempUnit: null,
    autoMilesCalculation: false,
    totalMiles: null,
    haulingFeeMethod: null,
    haulingRate: null,
    haulingAmount: null,
    fuelSurchageMethod: null,
    fuelSurchageRate: null,
    fuelSurchageAmount: null,
    extraItems: [],
    isArchived: false,
    createdAt: '2021-07-12T02:40:43.898Z',
    updatedAt: '2021-07-14T19:53:52.588Z',
    customer: {
      id: '0Fn4bALZw57Js0spXlOq',
      updatedAt: { seconds: 1625886884, nanoseconds: 714608000 },
      displayName: 'Grand Falls Agromart Ltd',
      type: 'CUSTOMER',
      legalName: 'Grand Falls Agromart Ltd',
      createdAt: { seconds: 1625886884, nanoseconds: 714608000 },
      address: {
        street1: 'PO Box 7875, 38 Després Rd',
        city: 'Grand Falls',
        country: '',
        street2: '',
        zipOrPostalCode: 'E3Z3E8',
        stateOrProvince: 'NB',
      },
      archived: false,
      primaryContact: {
        email: 'stephen.savage@grandfallsagromart.com',
        lastName: '',
        firstName: '',
        phoneNumber: '',
      },
    },
    pickupLocationInfo: {
      id: 'IKFftryt8XPKoOCOVPwk',
      driverInstructions: null,
      name: 'Port de montréal',
      requiresAppointment: null,
      type: null,
      unlocode: null,
      organizationId: 'XRHZRjwnpoAs968gWFJD',
      address: {
        stateOrProvince: 'QC',
        street1: '3335',
        country: 'CA',
        street2: '',
        city: 'VILLE',
        zipOrPostalCode: 'CODEPOSTAL',
      },
      consigneeOrConsignor: {
        phoneNumber: null,
        email: null,
        lastName: null,
        firstName: null,
      },
    },
    deliveryLocationInfo: {
      id: 'jNBbso0wZaYJS6c3xnzW',
      name: 'LOC AMF 2',
      organizationId: 'pGlzdtRyHlzYWmn6Rmkb',
      driverInstructions: null,
      requiresAppointment: null,
      consigneeOrConsignor: {
        email: null,
        lastName: null,
        phoneNumber: null,
        firstName: null,
      },
      unlocode: null,
      address: {
        zipOrPostalCode: 'G0V 1S0',
        city: 'St Fulgence',
        stateOrProvince: 'QC',
        country: 'CA',
        street2: null,
        street1: '281 St Jean Baptiste St',
      },
      type: null,
    },
  },
  {
    id: 'du2veI2m4hLqKUgAnw5J',
    bookingNumber: 'V120721-03',
    dispatchNumber: '',
    purchaseOrderNumber: null,
    trackingCode: 'AMF-1626058600519',
    type: 0,
    status: 0,
    pickupLocationId: 'Z4xfq3y2GPAOTk9RFAwW',
    pickupSchedule: null,
    pickupDateType: 'DATE',
    pickupDate: '2021-07-14T12:00:00.000Z',
    pickupDateTimeFrom: null,
    pickupDateTimeTo: null,
    pickupContactName: '',
    pickupContactEmail: null,
    pickupContactPhoneNumber: null,
    pickupContactFax: null,
    pickupBOL: '1407202108-1',
    pickupInstructions: '',
    deliveryLocationId: 'Z5PXlXznLNlYt0R2ADmC',
    deliverySchedule: null,
    deliveryDateType: 'DATE',
    deliveryDate: null,
    deliveryDateTimeFrom: null,
    deliveryDateTimeTo: null,
    deliveryContactName: null,
    deliveryContactEmail: null,
    deliveryContactPhoneNumber: null,
    deliveryContactFax: null,
    deliveryBOL: '1407202108-2',
    deliveryInstructions: '',
    deliveryDealine: null,
    customerId: '6L3B4JhHwDFlT5jvFIz4',
    shipperId: 'pGlzdtRyHlzYWmn6Rmkb',
    consigneeId: 'V860Ucnq1LN8mHnCoFZi',
    organizationId: 'pGlzdtRyHlzYWmn6Rmkb',
    freightDescription: 'Fouin',
    freightWeight: '1',
    freightWeightUnit: 't',
    freightNotes: null,
    freightEstimatedWeight: null,
    freightActualWeight: null,
    trailerGroup: 'DUMP',
    isLoadTypeHazMat: false,
    isLoadTypeOversized: true,
    isLoadTypeReefer: true,
    isLoadTypeTanker: false,
    reeferTemp: '50',
    reeferTempUnit: null,
    autoMilesCalculation: false,
    totalMiles: null,
    haulingFeeMethod: null,
    haulingRate: null,
    haulingAmount: null,
    fuelSurchageMethod: null,
    fuelSurchageRate: null,
    fuelSurchageAmount: null,
    extraItems: [],
    isArchived: false,
    createdAt: '2021-07-12T02:56:40.519Z',
    updatedAt: '2021-07-13T23:25:29.282Z',
    customer: {
      createdAt: { seconds: 1625886888, nanoseconds: 850448000 },
      id: '6L3B4JhHwDFlT5jvFIz4',
      archived: false,
      type: 'CUSTOMER',
      legalName: 'Ferme Jacmi SENC',
      primaryContact: {
        email: 'thibault.ja@outlook.com',
        firstName: '',
        lastName: '',
        phoneNumber: '(418) 882-8308',
      },
      updatedAt: { seconds: 1625886888, nanoseconds: 850448000 },
      address: {
        street1: '515, rang de la Montagne',
        stateOrProvince: 'Québec',
        zipOrPostalCode: '',
        street2: '',
        city: 'St-Anselme',
        country: 'G0R 2N0',
      },
      displayName: 'Ferme Jacmi ',
    },
    pickupLocationInfo: {
      id: 'Z4xfq3y2GPAOTk9RFAwW',
      name: 'LOC AMF 1',
      organizationId: 'pGlzdtRyHlzYWmn6Rmkb',
      type: null,
      address: {
        street1: '2456 rue Parc',
        city: 'Sherbrooke',
        zipOrPostalCode: ' \tJ1E 3B2',
        country: 'CA',
        stateOrProvince: 'QC',
        street2: null,
      },
      unlocode: null,
      consigneeOrConsignor: {
        firstName: null,
        email: null,
        lastName: null,
        phoneNumber: null,
      },
      driverInstructions: null,
      requiresAppointment: null,
    },
    deliveryLocationInfo: {
      id: 'Z5PXlXznLNlYt0R2ADmC',
      driverInstructions: null,
      organizationId: 'V860Ucnq1LN8mHnCoFZi',
      address: {
        street1: '4040 Township Rd',
        stateOrProvince: 'AB',
        zipOrPostalCode: 'T9V 0L9',
        street2: null,
        city: 'Lloydminster',
        country: 'CA',
      },
      type: null,
      consigneeOrConsignor: {
        firstName: null,
        phoneNumber: null,
        email: null,
        lastName: null,
      },
      name: 'LOC ACME 3 - 1',
      unlocode: null,
      requiresAppointment: false,
    },
  },
];
