import { Environment } from './interface';

// export const environment: Environment = {
//   appName: 'e-trucking',
//   production: true,
//   serviceWorker: true,
//   serverUrl: 'us-central1-tamf-e-trucking-prod',
//   useEmulators: false,
//   pluginModules: [],
//   firebase: {
//     apiKey: 'AIzaSyDwJ2GZK30DDzmNCJrDIbyd5dXOMlobiL0',
//     authDomain: 'tamf-e-trucking-dev.firebaseapp.com',
//     databaseURL: 'https://tamf-e-trucking-dev.firebaseio.com',
//     projectId: 'tamf-e-trucking-dev',
//     storageBucket: 'tamf-e-trucking-dev.appspot.com',
//     messagingSenderId: '554716293551',
//     appId: '1:554716293551:web:61c0ebf57e7f593d7f275a',
//     measurementId: 'G-XCN5XFMRYY',
//   },
// };

/* apiKey: 'AIzaSyDP0R3pPEj2XfIHqIgEHdmgXQawQ1F4Rbg',
authDomain: 'tamf-e-trucking-prod.firebaseapp.com',
databaseURL: 'https://tamf-e-trucking-prod.firebaseio.com',
projectId: 'tamf-e-trucking-prod',
storageBucket: 'tamf-e-trucking-prod.appspot.com',
messagingSenderId: '622012676602',
appId: '1:622012676602:web:0a498983524b76d39b8c43',
measurementId: 'G-7R37QQVQDW', */

export const environment: Environment = {
  appName: 'e-trucking',
  production: true,
  functionsDomain: 'us-central1-tamf-e-trucking-dev',
  serviceWorker: true,
  useEmulators: false,
  firebase: {
    apiKey: 'AIzaSyDwJ2GZK30DDzmNCJrDIbyd5dXOMlobiL0',
    authDomain: 'tamf-e-trucking-dev.firebaseapp.com',
    databaseURL: 'https://tamf-e-trucking-dev.firebaseio.com',
    projectId: 'tamf-e-trucking-dev',
    storageBucket: 'tamf-e-trucking-dev.appspot.com',
    messagingSenderId: '554716293551',
    appId: '1:554716293551:web:61c0ebf57e7f593d7f275a',
    measurementId: 'G-XCN5XFMRYY',
  },
  pluginModules: [],
};
