import { Provider } from '@angular/core';
import { RouteReuseStrategy, Routes, ROUTES } from '@angular/router';
import {
  AppRoute,
  ShellRouteReuseStrategy,
} from '@etrucking/shared/uikit/core';

import { HomeComponent } from './components';
import { MainComponent } from './components';

export const mainRoutes: AppRoute[] = [
  {
    path: '**',
    redirectTo: 'notfound',
  },
  {
    path: 'notfound',
    loadChildren: () =>
      import('./components/notfound').then((m) => m.NotFoundPageModule),
  },
];

export const authRoutes: AppRoute[] = [
  {
    path: 'iam',
    loadChildren: () =>
      import('@etrucking/iam/shell-admin').then((m) => m.AuthShellModule),
  },
];

/**
 * Provides helper methods to create routes.
 */
export class ShellRoutingBuilder {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static main(routes: Routes): AppRoute {
    return {
      path: '',
      component: MainComponent,
      children: routes,
      canActivate: [],
      // Reuse ShellComponent instance when navigating between child views
    };
  }
}

/**
 * Registers a DI provider for global routes.
 *
 * @param {AppRoute[]} appRoutes
 * @returns {*}
 */
export function provideRoutes(appRoutes: AppRoute[]): Provider[] {
  return [
    {
      provide: ROUTES,
      multi: true,
      useValue: authRoutes,
    },
    {
      provide: ROUTES,
      multi: true,
      useValue: ShellRoutingBuilder.main(appRoutes),
    },
    {
      provide: ROUTES,
      multi: true,
      useValue: ShellRoutingBuilder.main(mainRoutes),
    },
    {
      provide: RouteReuseStrategy,
      useClass: ShellRouteReuseStrategy,
    },
  ];
}
