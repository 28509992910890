import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { SidePanelModule } from '@etrucking/shared/uikit/sidepanel';

@Component({
  selector: 'etrk-transportamf-home',
  template: `
    <ul>
      <li *ngFor="let item of list" [ngClass]="'route-animations-elements'">
        <span>Liste élement </span>
        <span>{{ item }}</span>
      </li>
    </ul>
    <etrk-sidepanel [ngClass]="'route-animations-elements'">
      <ul>
        <li *ngFor="let item of list">
          <span>Liste élement </span>
          <span>{{ item }}</span>
        </li>
      </ul>
    </etrk-sidepanel>
  `,
})
export class HomeComponent implements OnInit {
  list: number[] = [];

  constructor() {}

  private init() {
    const length = 20;
    const arr = [];
    for (let i = 0; i < length; i++) {
      arr.push(i);
    }

    this.list = [...arr];
  }

  ngOnInit() {
    this.init();
  }
}

@NgModule({
  imports: [CommonModule, SidePanelModule],
  declarations: [HomeComponent],
  exports: [HomeComponent],
})
export class HomeComponentModule {}
