import { ContactMethod, Invite, InviteStatus } from '@etrucking/iam/core';
import { setFullTimestamp } from '@etrucking/shared/state';

export function createInvite(params: Partial<Invite>) {
  const contactMethod = params.contactMethod
    ? params.contactMethod
    : ContactMethod.Email;

  return {
    contactMethod,
    email: params.contactInfo,
    contactInfo: params.contactInfo,
    organizationId: params.organizationId,
    registration: params.registration,
    status: InviteStatus.Pending,
    expiryDate: '',
    invitedBy: params.invitedBy,
    ...setFullTimestamp(),
  } as Invite;
}
