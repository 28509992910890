import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Invite } from '@etrucking/iam/core';

export interface InvitesState
  extends EntityState<Invite, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'iam.invites' })
export class InvitesStore extends EntityStore<InvitesState> {
  constructor() {
    super();
  }
}
