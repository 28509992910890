import { Injectable } from '@angular/core';

import { RouteAnimationType } from './types';

@Injectable({
  providedIn: 'root',
})
export class AnimationsService {
  constructor() {
    AnimationsService.routeAnimationType = 'ALL';
  }

  private static routeAnimationType: RouteAnimationType = 'ALL';

  static isRouteAnimationsType(type: RouteAnimationType) {
    return AnimationsService.routeAnimationType === type;
  }

  updateRouteAnimationType(
    pageAnimations: boolean,
    elementsAnimations: boolean
  ) {
    AnimationsService.routeAnimationType =
      pageAnimations && elementsAnimations
        ? 'ALL'
        : pageAnimations
        ? 'PAGE'
        : elementsAnimations
        ? 'ELEMENTS'
        : 'NONE';
  }
}
