import { Observable, Subject, Subscription } from 'rxjs';

import { Location } from '@angular/common';
import { ChangeDetectorRef, Directive, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { TitleService } from '../services';
import { Logger } from '@etrucking/shared/utils/logger';
import { ActionSheetController, NavController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';

@Directive()
export abstract class BasePage implements OnDestroy {
  protected abstract name: string;
  protected subscriptions: Subscription[] = [];
  #logger!: Logger;
  /**
   *
   *
   * @type {Subject<void>}
   * @memberof BaseView
   */
  #destroy$!: Subject<void>;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected routerQuery: RouterQuery,
    protected location: Location,
    protected title: TitleService
  ) {}

  protected routeParams = this.routerQuery.selectParams();
  protected routeQueryParams = this.routerQuery.selectQueryParams();

  /**
   *
   *
   * @readonly
   * @protected
   * @type {Observable<void>}
   * @memberof BaseView
   */
  protected get destroy$(): Observable<void> {
    if (!this.#destroy$) {
      this.#destroy$ = new Subject();
    }

    return this.#destroy$.asObservable();
  }

  protected get logger() {
    if (!this.#logger) {
      this.#logger = new Logger(this.name);
    }

    return this.#logger;
  }

  protected navigate(url: string) {
    this.router.navigateByUrl(url);
  }

  onPageBack() {
    this.location.back();
  }

  onPageRedirect(url: string) {
    this.logger.debug('onPageRedirect()', url);
    this.router.navigateByUrl(url);
  }

  onPageTitleChange(title: string) {
    this.title.setTitle(title || '');
  }

  ngOnDestroy(): void {
    this.logger.debug(`:ngOnDestroy()`);
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

@Directive()
export abstract class MobilePage extends BasePage {
  pageTitle = '';

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected routerQuery: RouterQuery,
    protected location: Location,
    protected title: TitleService,
    protected navCtrl: NavController,
    protected actionSheetCtrl: ActionSheetController,
    protected cd: ChangeDetectorRef,
    public readonly auth: AngularFireAuth
  ) {
    super(router, route, routerQuery, location, title);
  }

  onPageTitleChange(title: string) {
    this.pageTitle = title;
    super.onPageTitleChange(title);
  }

  onPageBack() {
    this.navCtrl.back();
  }

  goto(route: any) {
    //this.router.navigate(['..', route.detail.value])

    this.logger.debug(route, this.route);
  }
}
