export interface AuthCredentials {
  email: string;
  password: string;
}

export enum Session {
  Anonymous = 'ANONYMOUS',
  Pending = 'PENDING',
  Authenticated = 'AUTHENTICATED',
  Rejected = 'REJECTED',
}
