import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { NavLink } from '../model';
import { UiStore } from './ui.store';

@Injectable({ providedIn: 'root' })
export class UiService {
  constructor(private uiStore: UiStore) {}

  setNavLinks(navLinks: NavLink[]) {
    this.uiStore.update({
      navLinks,
    });
  }

  setTitle(title: string) {
    this.uiStore.update({ title });
  }

  setSidebarHeader(options: {
    showBackControl: boolean;
    showIcon: boolean;
    icon: string;
    imageUrl: string;
    title: string;
    description: string;
  }) {
    this.uiStore.update({ sidebarHeader: { ...options } });
  }

  search(searchTerm: string) {
    const minSearchCharacters = 3;
    this.uiStore.update({ searchTerm });
    this.uiStore.update({
      searchResultsVisible: searchTerm.length >= minSearchCharacters,
    });
  }

  toggleSidenavExtended() {
    this.uiStore.update((state) => ({
      sidenavExtended: !state.sidenavExtended,
    }));
  }

  toggleSearchMenu() {
    this.uiStore.update((state) => ({
      backdropSearchVisible: !state.backdropSearchVisible,
    }));
  }

  toggleNewMenu() {
    this.uiStore.update((state) => ({
      backdropNewVisible: !state.backdropNewVisible,
    }));
  }

  toggleNavModile() {
    this.uiStore.update((state) => ({
      showExtendedNavMobile: !state.showExtendedNavMobile,
    }));
  }

  enableSidePanel() {
    this.uiStore.update({ sidePanelEnabled: true });
  }

  disableSidePanel() {
    this.uiStore.update({ sidePanelEnabled: false });
  }
}
