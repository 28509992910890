import { InjectionToken } from '@angular/core';

/**
 * A DI token that defines the default route for apps.
 *
 * @publicApi
 */
export const DEFAULT_ROUTE_REDIRECT = new InjectionToken<string>(
  'DEFAULT_ROUTE_REDIRECT'
);

/**
 * A DI token that defines app links.
 *
 * @publicApi
 */
export const APP_NAV_LINKS = new InjectionToken<string>('APP_NAV_LINKS');

export const APP_NAME = new InjectionToken<string>('APP_NAME');

export function noop() {
  return () => {};
}
