import { BaseModel } from '@etrucking/shared/core';

import { RoleCode } from './role.model';

export interface Invite extends BaseModel {
  id?: string;
  email: string;
  contactInfo: string;
  contactMethod: ContactMethod;
  registration: InviteRegistration;
  expiryDate: any;
  invitedBy: string;
  status: InviteStatus;
  role?: RoleCode;
}

export enum InviteStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Expired = 'EXPIRED',
}

export enum ContactMethod {
  Email = 'EMAIL',
  Phone = 'PHONE',
}

export enum InviteRegistration {
  Driver = 'DRIVER',
  Operator = 'OPERATOR',
  Admin = 'ADMIN',
}
