/* eslint-disable @typescript-eslint/no-explicit-any */
/// <reference types="@types/googlemaps" />
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  NgModule,
  NgZone,
  OnDestroy,
  Output,
} from '@angular/core';
import { NgLazyloadScriptService } from '@ueler/ng-lazyload-script';
import { Observable } from 'rxjs';

const scriptURL =
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyB3j0q08eR6XDAFzli1MnsR8QS1gDoWL44&libraries=places&language=en';
/**
 *
 *
 * @export
 * @class GooglePlacesDirective
 * @implements {AfterViewInit}
 */
@Directive({
  selector: '[etrkGooglePlace]',
})
export class GooglePlacesDirective implements AfterViewInit, OnDestroy {
  /**
   *
   *
   * @type {EventEmitter<any>}
   * @memberof GooglePlacesDirective
   */
  @Output() addressChange: EventEmitter<any> = new EventEmitter();
  /**
   *
   *
   * @private
   * @type {HTMLInputElement}
   * @memberof GooglePlacesDirective
   */
  private el: HTMLInputElement;

  private listener!: google.maps.MapsEventListener;
  /**
   * Creates an instance of GooglePlacesDirective.
   * @param {ElementRef} elRef
   * @param {NgLazyloadScriptService} scriptLoader
   * @param {NgZone} ngZone
   * @memberof GooglePlacesDirective
   */
  constructor(
    private elRef: ElementRef,
    private scriptLoader: NgLazyloadScriptService,
    private ngZone: NgZone
  ) {
    //elRef will get a reference to the element where
    //the directive is placed
    this.el = elRef.nativeElement;
  }
  /**
   *
   *
   * @readonly
   * @type {Observable<unknown>}
   * @memberof GooglePlacesDirective
   */
  get loadScript$(): Observable<unknown> {
    return this.scriptLoader.loadScript(scriptURL);
  }
  /**
   *
   *
   * @memberof GooglePlacesDirective
   */
  ngAfterViewInit() {
    this.el = this.elRef.nativeElement;

    this.ngZone.runOutsideAngular(() => {
      this.loadScript$.subscribe(() => this.init());
    });
  }

  ngOnDestroy(): void {
    if (this.listener) {
      google.maps.event.removeListener(this.listener);
    }
  }

  /**
   *
   *
   * @memberof GooglePlacesDirective
   */
  init() {
    const input = this.el as HTMLInputElement;
    const options: google.maps.places.AutocompleteOptions = {
      componentRestrictions: { country: ['ca', 'us'] },
      fields: ['address_components', 'geometry'],
      strictBounds: false,
      types: [],
    };

    const autocomplete = new google.maps.places.Autocomplete(input, options);

    this.listener = google.maps.event.addListener(
      autocomplete,
      'place_changed',
      () => {
        this.addressChange.emit(
          this.getFormattedAddress(autocomplete.getPlace())
        );
      }
    );
  }

  /**
   *
   *
   * @param {*} place
   * @return {*}
   * @memberof GooglePlacesDirective
   */
  getFormattedAddress(place: google.maps.places.PlaceResult) {
    //@params: place - Google Autocomplete place object
    //@returns: location_obj - An address object in human readable format
    const location: any = {};

    location.formatted_address = place.formatted_address;
    location.geometry = place.geometry;

    console.log(place);

    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          location.street_number = {
            long: component.long_name,
            short: component.short_name,
          };
          break;
        }

        case 'route': {
          location.route = {
            long: component.long_name,
            short: component.short_name,
          };
          break;
        }

        case 'postal_code': {
          location.postal_code = {
            long: component.long_name,
            short: component.short_name,
          };
          break;
        }

        case 'postal_code_suffix': {
          location.postal_code_suffix = {
            long: component.long_name,
            short: component.short_name,
          };
          break;
        }

        case 'locality':
          location.locality = {
            long: component.long_name,
            short: component.short_name,
          };
          break;

        case 'administrative_area_level_1': {
          location.admin_area_l1 = {
            long: component.long_name,
            short: component.short_name,
          };
          break;
        }

        case 'country':
          location.country = {
            long: component.long_name,
            short: component.short_name,
          };
          break;
      }
    }
    return location;
  }
}

@NgModule({
  imports: [],
  exports: [GooglePlacesDirective],
  declarations: [GooglePlacesDirective],
  providers: [],
})
export class GooglePlaceModule {}
