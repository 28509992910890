import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SidePanelComponent } from './sidepanel.component';

@NgModule({
  imports: [CommonModule, PortalModule],
  declarations: [SidePanelComponent],
  exports: [SidePanelComponent],
})
export class SidePanelModule {}
