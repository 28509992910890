import { Organization } from '@etrucking/iam/core';
import { Customer } from '@etrucking/crm/core';

export class OrganizationsMapper {
  toDomain(params: any): Partial<Organization> {
    return {
      id: params?.id,
      displayName: params?.displayName,
      legalName: params?.legalName,
      address: params?.address,
      archived: params?.archived,
      primaryContact: params?.primaryContact,
      type: params?.type,
      avatarUrl: params?.avatarUrl,
      createdAt: params?.createdAt,
      updatedAt: params?.updatedAt,
    };
  }
}
