import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconComponent } from './icon.component';

/**
 * Icon Component Module
 *
 * @export
 * @class IconModule
 */
@NgModule({
  imports: [CommonModule],
  declarations: [IconComponent],
  exports: [IconComponent],
})
export class IconModule {}
