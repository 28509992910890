import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Profile } from '@etrucking/iam/core';

export interface ProfilesState
  extends EntityState<Profile, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'iam.profiles', idKey: 'userId' })
export class ProfilesStore extends EntityStore<ProfilesState> {
  constructor() {
    super();
  }
}
