import { CommonModule } from '@angular/common';
import '@angular/common/locales/global/fr';
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireAuth, USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/auth';
import {
  USE_EMULATOR as USE_DATABASE_EMULATOR,
  URL as DATABASE_URL,
} from '@angular/fire/database';
import {
  AngularFirestoreModule,
  USE_EMULATOR as USE_FIRESTORE_EMULATOR,
  SETTINGS as FIRESTORE_SETTINGS,
} from '@angular/fire/firestore';
import {
  AngularFireFunctionsModule,
  USE_EMULATOR as USE_FUNCTIONS_EMULATOR,
  ORIGIN as FUNCTIONS_ORIGIN,
} from '@angular/fire/functions';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { akitaConfig, persistState } from '@datorama/akita';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '@environment/frontend';
import {
  AppRoute,
  APP_NAME,
  APP_NAV_LINKS,
  NavLink,
  noop,
  RouterHandler,
} from '@etrucking/shared/uikit/core';
import { IconModule } from '@etrucking/shared/uikit/icon';
import {
  popperVariation,
  TippyModule,
  tooltipVariation,
} from '@ngneat/helipopper';
import { HotToastModule } from '@ngneat/hot-toast';
import * as Sentry from '@sentry/angular';
import { debounceTime } from 'rxjs/operators';
import { MainComponent } from './components';
import { HomeComponentModule } from './components/home';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { provideRoutes } from './shell.routing';

@NgModule({
  declarations: [MainComponent, MainLayoutComponent],
  imports: [
    BrowserModule,
    CommonModule,
    IconModule,
    HomeComponentModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireFunctionsModule,
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    RouterModule.forRoot([], {
      initialNavigation: 'enabledNonBlocking',
      enableTracing: false,
      relativeLinkResolution: 'legacy',
      paramsInheritanceStrategy: 'always',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorker,
    }),
    AkitaNgRouterStoreModule,
    environment.pluginModules,
    HotToastModule.forRoot(),
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      },
    }),
  ],
  exports: [RouterModule, BrowserAnimationsModule, AkitaNgRouterStoreModule],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'CA' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class SharedShellWebModule {
  /**
   * Creates an instance of SharedShellWebModule.
   * @param {SharedShellWebModule} parentModule
   * @memberof SharedShellWebModule
   */
  constructor(@Optional() @SkipSelf() parentModule: SharedShellWebModule) {
    persistState({
      include: ['auth', 'ui.sidenavExtended'],
      preStorageUpdateOperator: () => debounceTime(2000),
    });
    akitaConfig({ resettable: true });

    // Import guard
    if (parentModule) {
      throw new Error(`
        ${parentModule} has already been loaded.
        Import SharedAppShellModule module in the AppModule only.
      `);
    }
  }
  /**
   * Initializes Application Shell config
   *
   * @static
   * @param {Routes} appRoutes
   * @param {*} [options]
   * @returns {ModuleWithProviders<SharedShellWebModule>}
   * @memberof SharedShellWebModule
   */
  static initApp(
    appRoutes: AppRoute[],
    appLinks: NavLink[] = [],
    options?: any
  ): ModuleWithProviders<SharedShellWebModule> {
    return {
      ngModule: SharedShellWebModule,
      providers: [
        provideRoutes(appRoutes),
        { provide: APP_NAV_LINKS, useValue: appLinks },
        {
          provide: APP_NAME,
          useValue: (options && options.appName) || 'e-Trucking',
        },
        { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9099] : undefined },
        {
          provide: APP_INITIALIZER,
          multi: true,
          deps: [AngularFireAuth],
          useFactory: initializeApp,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: noop,
          deps: [RouterHandler],
          multi: true,
        },
        {
          provide: DATABASE_URL,
          useValue: environment.useEmulators
            ? `http://localhost:9000?ns=${environment.firebase.projectId}`
            : undefined,
        },
        {
          provide: FIRESTORE_SETTINGS,
          useValue: environment.useEmulators
            ? { host: 'localhost:8080', ssl: false }
            : {},
        },
        //{ provide: FUNCTIONS_ORIGIN, useFactory: environment.useEmulators ? 'http://localhost:5001' : undefined },
      ],
    };
  }
}

export function initializeApp(afAuth: AngularFireAuth): () => Promise<void> {
  return () => {
    return new Promise<void>((resolve) => {
      if (!environment.useEmulators) {
        return resolve();
      } else {
        //firebase.auth().settings.appVerificationDisabledForTesting = true;
        
        afAuth.useEmulator(`http://localhost:9099`).then(() => {
          resolve();
        });
      }
    });
  };
}

