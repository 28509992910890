import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Session } from '@etrucking/iam/core';
import { AuthQuery, AuthService } from '@etrucking/iam/state';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
  constructor(private authQuery: AuthQuery, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.authQuery.session$.pipe(
      map((session) => session === Session.Authenticated),
      map((authenticated) => {
        if (authenticated) {
          console.log('AuthenticatedGuard:authenticated', authenticated);
          this.router.navigate(['/']).then(() => {
            console.log('AuthenticatedGuard:NAV COMPLETE');
          });

          return false;
        }

        return true;
      }),
      take(1)
    );
  }
}
