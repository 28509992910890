import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Session } from '@etrucking/iam/core';
import { AuthQuery } from '@etrucking/iam/state';
import { Logger } from '@etrucking/shared/utils/logger';

const log = new Logger('AnonymousGuard');

@Injectable({
  providedIn: 'root',
})
export class AnonymousGuard implements CanActivate {
  constructor(private authQuery: AuthQuery, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.checkAuthenticatedUser(state.url);
  }

  private checkAuthenticatedUser(nextUrl: string) {
    return this.authQuery.session$.pipe(
      take(1),
      map((session) => session === Session.Authenticated),
      map((authenticated) => authenticated),
      tap((authenticated) => log.debug('canActivate()', authenticated)),
      tap((authenticated) => {
        if (authenticated) return;
        this.router.navigate(['/', 'iam', 'signin'], {
          queryParamsHandling: 'merge',
          queryParams: { nextUrl },
        });

        log.debug('checkAuthenticatedUser() > redirect to login');
      })
    );
  }
}
