import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'etrucking-admin-portal-home',
  template: `
    <div class="empty">
      <div class="empty__container">
        <div class="empty__preview">
          <img class="empty__pic" src="/assets/img/pic-1.png" alt="Empty" />
        </div>
        <div class="empty__title title title_lg">
          <p>Données réseau à insuffisantes</p>
        </div>
        <div class="empty__text">Commençer par parcourir notre réseau</div>
        <a class="empty__btn btn" routerLink="/crm">Voir le réseau</a>
      </div>
    </div>
  `,
})
export class HomeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
