import { filter, map, tap } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
// eslint-disable-next-line
import { Logger } from '@etrucking/shared/utils/logger';

import { NavLink } from '../model';
import { TitleService } from '../services/title.service';
import { UiService } from '../state';
import { APP_NAV_LINKS } from '../tokens';

const log = new Logger('RouterHandler');

@Injectable({ providedIn: 'root' })
export class RouterHandler {
  constructor(
    private routerQuery: RouterQuery,
    private titleService: TitleService,
    private uiService: UiService,
    @Inject(APP_NAV_LINKS) public navLinks: NavLink[]
  ) {
    this.init();
  }

  init() {
    this.routerQuery
      .selectData()
      .pipe(
        filter((data) => data && !!data.title),
        map((data) => data.title),
        tap((title: string) => {
          log.debug(`Navigating ‣ ${title}`);
          this.titleService.setTitle(title);
        })
      )
      .subscribe();

    this.buildNavLinks();
  }

  buildNavLinks() {
    this.uiService.setNavLinks(this.navLinks);
  }
}
