import { ChangeDetectorRef, Directive, OnDestroy } from '@angular/core';
import { Logger } from '@etrucking/shared/utils/logger';
import { Subject } from 'rxjs';

@Directive()
export abstract class BaseComponent implements OnDestroy {
  #destroy$!: Subject<void>;
  #logger!: Logger;

  isLoading = true;
  isInitialized = false;

  protected abstract name: string;

  protected get destroy$() {
    if (!this.#destroy$) {
      this.#destroy$ = new Subject();
    }

    return this.#destroy$.asObservable();
  }

  protected get logger() {
    if (!this.#logger) {
      this.#logger = new Logger(this.name);
    }

    return this.#logger;
  }

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  protected runChangeDetection() {
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 100);
  }

  ngOnDestroy(): void {
    if (this.#destroy$) {
      this.#destroy$.next();
      this.#destroy$.complete();
      this.logger.debug(`${this.name}:ngOnDestroy()`);
    }
  }
}
