import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
} from '@angular/core';

/**
 * Icon Component
 *
 * @export
 * @class IconComponent
 */
@Component({
  selector: 'etrk-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  /**
   * name used to describe the icon
   *
   * @memberof IconComponent
   */
  @Input() name = 'la-universal-access';
}
