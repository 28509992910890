import { CollectionConfig, CollectionService } from 'akita-ng-fire';

import { Injectable } from '@angular/core';

import { AccountsState, AccountsStore } from './accounts.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'accounts', idKey: 'userId' })
export class AccountsService extends CollectionService<AccountsState> {
  constructor(store: AccountsStore) {
    super(store);
  }
}
