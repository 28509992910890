/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable, of } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { AuthQuery, AuthService } from '@etrucking/iam/state';
import { routeAnimations } from '@etrucking/shared/uikit/animation';
import { UiQuery, UiService } from '@etrucking/shared/uikit/core';
import { Logger } from '@etrucking/shared/utils/logger';
import { SwUpdate } from '@angular/service-worker';
import { HotToastService } from '@ngneat/hot-toast';

const log = new Logger('MainComponent');

@Component({
  selector: 'etrk-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [routeAnimations],
})
export class MainComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public uiCommand: UiService,
    public uiQuery: UiQuery,
    private authQuery: AuthQuery,
    private authService: AuthService,
    private readonly updates: SwUpdate,
    private toastService: HotToastService
  ) {
    this.updates.available.subscribe(() => {
      this.showAppUpdateAlert();
      console.log('UPDATE IS AVAILABLE');
    });
  }

  @ViewChild('swUpdateTpl', { static: true })
  private swUpdateTpl!: TemplateRef<unknown>;

  pageSidenavCollapsed = false;

  title$ = this.uiQuery.title$;
  searchTerm$ = this.uiQuery.searchTerm$;
  sidenavExtended$ = this.uiQuery.sidenavExtended$;
  showExtendedNavMobile$ = this.uiQuery.showExtendedNavMobile$;
  backdropNewVisible$ = this.uiQuery.backdropNewVisible$;
  backdropSearchVisible$ = this.uiQuery.backdropSearchVisible$;
  searchResultsVisible$ = this.uiQuery.searchResultsVisible$;
  sidebarPictureUrl$ = this.uiQuery.sidebarHeaderImageUrl$;
  sidebarShowIcon$ = this.uiQuery.sidebarHeaderShowIcon$;
  sidebarIcon$ = this.uiQuery.sidebarHeaderIcon$;
  sidebarShowBackControl$ = this.uiQuery.sidebarHeaderShowBackControl$;
  sidebarTitle$ = this.uiQuery.sidebarHeaderTitle$;
  sidebarText$ = this.uiQuery.sidebarHeaderDescription$;
  sidePanelEnabled$ = this.uiQuery.sidePanelEnabled$;
  userDisplayName$ = this.authQuery.profile$.pipe(
    filter((profile) => !!profile),
    map(({ firstName, lastName }: any) => `${firstName} ${lastName}`),
    shareReplay(1)
  );
  userAvatar$ = this.userDisplayName$.pipe(
    map((userDisplayName: string) => userDisplayName.split(' ', 2)),
    map(
      (displayNames: string[]) =>
        `${displayNames[0]?.charAt(0)}${displayNames[1]?.charAt(0)}`
    )
  );
  user$: Observable<any> | undefined;

  primaryNavLinks$ = this.uiQuery.primaryNavLinks$;
  secondaryNavLinks$ = this.uiQuery.secondaryNavLinks$;

  toastRef!: any;

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  back() {
    this.location.back();
  }

  async signOut() {
    await this.authService.logout();
  }

  prepareRoute(outlet: RouterOutlet) {
    console.log(outlet.activatedRouteData);
    return outlet.activatedRouteData['title'];
  }

  async onSearch(event: any) {
    //this.uiCommand.search(event.target.value);
  }

  showAppUpdateAlert() {
    // Use MatDialog or ionicframework's AlertController or similar
    this.toastRef = this.toastService.show(this.swUpdateTpl, {
      autoClose: false,
      dismissible: true,
      theme: 'snackbar',
    });
  }

  doAppUpdate() {
    this.toastRef.close({ dismissedByAction: true });
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}
