import { CollectionConfig, CollectionService } from 'akita-ng-fire';

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { setFullTimestamp } from '@etrucking/shared/state';
import { Logger } from '@etrucking/shared/utils/logger';

import { InvitesQuery, InvitesService } from '../invites';
import { OrganizationsQuery } from '../organizations';
import { ProfilesService } from '../profiles';
import { UsersState, UsersStore } from './users.store';

const logger = new Logger('UsersService');

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'users', idKey: 'uid' })
export class UsersService extends CollectionService<UsersState> {
  constructor(
    store: UsersStore,
    private auth: AngularFireAuth,
    private profile: ProfilesService,
    private invite: InvitesService,
    private inviteQuery: InvitesQuery,
    private organizationsQuery: OrganizationsQuery
  ) {
    super(store);
  }

  async register(params: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    organizationId: string;
    role: string;
    phoneNumber: string;
  }) {
    const write = this.batch();

    const { email, password, firstName, lastName, organizationId } = params;

    logger.debug('register() => params', params);

    const phoneNumber = params.phoneNumber
      ?.replace(/\-/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/\./g, '');

    const { createdAt, updatedAt } = setFullTimestamp();
    const userOrg = this.organizationsQuery.getEntity(organizationId);
    const invite = this.inviteQuery.getActive();
    const { user } = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );

    const { uid } = user as firebase.User;

    const registeredUser = {
      email,
      phoneNumber,
      uid,
      organizationId,
      role: `${userOrg?.type}_${invite?.registration}`,
      createdAt,
      updatedAt,
    };

    const registeredProfile = {
      avatarUrl: '',
      tenant: organizationId,
      isOnline: true,
      firstName,
      lastName,
      phoneNumber,
      userId: uid,
      createdAt,
      updatedAt,
    };

    logger.debug('register() => registeredUser', registeredUser);
    logger.debug('register() => registeredProfile', registeredProfile);

    await this.add(registeredUser, { write });
    await this.profile.add(registeredProfile, { write });

    if (invite?.registration === 'DRIVER') {
      write.set(this.db.firestore.collection('drivers').doc(uid), {
        driverLicenseExpiry: '',
        isEmployee: true,
        userId: uid,
      });
    }

    await this.invite.remove(invite?.id as string, { write });

    return write.commit();
  }
}
