import { NgModule } from '@angular/core';
import { SharedShellWebModule } from '@etrucking/shared/shell-web';

import { AppComponent } from './app.component';
import { links, routes } from './app.routing';
import { BillingComponent } from './billing';
import { HomeComponent } from './home';
import { MessagingService } from './messaging.service';

@NgModule({
  declarations: [AppComponent, HomeComponent, BillingComponent],
  imports: [
    SharedShellWebModule.initApp(routes, links, {
      appName: 'e-Trucking Admin',
    }),
  ],
  bootstrap: [AppComponent],
  providers: [MessagingService],
})
export class AppModule {}
