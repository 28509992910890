import {
  CdkPortal,
  DomPortalOutlet,
  Portal,
  PortalModule,
  PortalOutlet,
} from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  Injector,
  NgModule,
  OnDestroy,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'etrk-title-portal',
  template: `
    <ng-template cdk-portal>
      <ng-content></ng-content>
    </ng-template>
  `,
})
export class TitlePortalComponent implements AfterViewInit, OnDestroy {
  private outlet!: PortalOutlet;
  @ViewChild(CdkPortal)
  portal!: Portal<unknown>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  ngAfterViewInit(): void {
    // Create a portalHost from a DOM element
    this.outlet = new DomPortalOutlet(
      document.querySelector('#title-info__container') as Element,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );

    // Attach portal to host
    this.outlet.attach(this.portal);
  }

  ngOnDestroy(): void {
    this.outlet.detach();
  }
}

@NgModule({
  imports: [CommonModule, PortalModule],
  declarations: [TitlePortalComponent],
  exports: [TitlePortalComponent],
})
export class TitlePortalModule {}
