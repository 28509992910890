import { Subject, Subscription } from 'rxjs';

import {
  ChangeDetectorRef,
  Directive,
  Injectable,
  OnDestroy,
} from '@angular/core';
import { Logger } from '@etrucking/shared/utils/logger';

@Injectable()
export abstract class BasePresenter implements OnDestroy {
  protected abstract name: string;
  protected subscriptions: Subscription[];
  #destroy$!: Subject<void>;
  #logger!: Logger;

  get _name() {
    return this.name;
  }

  constructor() {
    this.subscriptions = [];
  }

  protected get destroy$() {
    if (!this.#destroy$) {
      this.#destroy$ = new Subject();
    }

    return this.#destroy$.asObservable();
  }

  protected get logger() {
    if (!this.#logger) {
      this.#logger = new Logger(this.name);
    }

    return this.#logger;
  }

  ngOnDestroy(): void {
    if (this.#destroy$) {
      this.#destroy$.next();
      this.#destroy$.complete();
    }

    this.logger.debug(`${this.name}:ngOnDestroy()`);
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
