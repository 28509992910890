import { Observable } from 'rxjs';

import { inject, InjectionToken } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';

export const ActiveIdToken = new InjectionToken<Observable<string>>(
  'ActiveId',
  {
    providedIn: 'any',
    factory: () => {
      return inject(RouterQuery).selectParams('id');
    },
  }
);
